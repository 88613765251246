export const Data = {
  profile: {
    name: "Cliff Chen (陳顥升)",
    ocupation: "Software Developer",
    location: "Miaoli, Taiwan",
    email: "cliffxzx@gmail.com",
    image: "images/avatar.jpg",
  },
  aboutMe: {
    label: "Education",
    description: `National Central University, Taoyuan, Taiwan <br/>
Bachelor of Computer Science and Information Engineering;  GPA: 3.2/4 Sep. 2019 -- Present (Jun. 2023)`,
  },
  skills: {
    technicalLabel: "technics",
    softLabel: "Skills",
    softSkills: [
      "Web Development",
      "Operating System Development",
      "Machine Learning",
      "Testing & Debugging",
    ],
    technicalSkills: [
      "HTML / CSS / JS / React",
      "C++ / Boost",
      "Typescript",
      "node.js / koa.js / nest.js",
      "Go / gin / sqlx",
      "Redis / MYSQL / postgreSQL",
      "Git / Docker",
    ],
  },
  socialMedia: {
    label: "SOCIAL",
    social: [
      {
        label: "github",
        name: "github",
        url: "https://github.com/cliffxzx",
        className: "bxl-github",
      },
      {
        label: "linkedin",
        name: "linkedin",
        url: "https://www.linkedin.com/in/cliffxzx",
        className: "bxl-linkedin-square",
      },
      {
        label: "facebook",
        name: "facebook",
        url: "https://www.facebook.com/cliffxzx",
        className: "bxl-facebook-square",
      },
    ],
  },
  experience: {
    works: [
      {
        title: "Software Engineer Intern",
        period: "Jul. 2021 - Jul. 2022",
        company: "Shopback",
        description: [
          "Web Extension Development",
          "Backend Services Development",
        ],
      },
    ],
    academic: [
      {
        career: "Computer Science and Information Engineering",
        date: "2019 - NOW",
        institution: "National Central University",
      },
    ],
    proyects: [
      // {
      //   name: "Onboarding Digital",
      //   company: "Banco de Chile",
      //   period: "Nov. 2019 - Ene. 2020",
      //   description: [
      //     "Control, desarrollo, securitización y despliegue de APIs en plataforma IBM API Connect.",
      //   ],
      // },
      // {
      //   name: "Captación Digital",
      //   company: "Banco Ripley",
      //   period: "Ene. 2020 - Feb. 2020",
      //   description: [
      //     "Control, desarrollo y securitización de APIs en plataforma IBM API Connect.",
      //     "Desarrollo frontend en Angular para el consumo de Web Services SOAP/REST apuntando a ambientes DEV/PRE-PROD/PROD como simulación.",
      //   ],
      // },
      // {
      //   name: "Agendamiento Remoto",
      //   company: "Gobierno de Chile",
      //   period: "Mar. 2020 - May. 2020",
      //   description: [
      //     "Desarrollo frontend y mobile con las tecnologias de Angular 6 e Ionic 4 para el agendamiento de horas en centros a lo largo de todo Chile.",
      //   ],
      // },
      // {
      //   name: "Altiuz Report Dynamic",
      //   company: "Altiuz",
      //   period: "Jun. 2020 - Jul. 2020",
      //   description: [
      //     "Desarrollo de servicio Java para la generación unitaria de documentos, sin la necesidad de tener almacenada la información del mismo en una fuente de datos.",
      //   ],
      // },
      // {
      //   name: "Desarrollos Tigo",
      //   company: "Computec S.A.S",
      //   period: "Ago. 2020 - Dic 2020",
      //   description: [
      //     "Desarrollo Java/Spring Boot de Web Service API REST para consulta y descarga de documentos almacenados en repositorios IBM CMOD.",
      //     "Desarrollo Java CRON para transferencia de documentos XML hacia servidor SFTP.",
      //     "Desarrollo Java CRON para carga de documentos PDFs hacia Azure Blob Storage.",
      //   ],
      // },
    ],
  },
};
